import { computedFrom } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';
export class MdcTagField {
    @bindable tags: string[];
    @bindable commonTags: string[];
    @bindable newTag: string;

    checkTagValue(event: KeyboardEvent) {
        if (event.code === 'Enter') {
            this.addTag();
        }
    }

    onMenuSelect(event) {
        this.newTag = event.data;
    }

    @computedFrom('newTag')
    get filteredTags() {
        if (this.newTag) {
            return this.commonTags.filter((tag) => {
                return tag.toUpperCase().includes(this.newTag.toUpperCase());
            });
        } else {
            return this.commonTags;
        }
    }

    addTag() {

        if (!this.tags) {
            this.tags = [];
        }
        if (!this.newTag) {
            return;
        }

        if (this.tags.includes(this.newTag)) {
            return;
        } else {
            const tags = this.newTag
                .split(',')
                .map((t) => t.trim()
                    // first replace dashes with spaces
                    .replace(/-/g, ' ')
                    // replace all non alpha numericals with empty
                    .replace(/[^ A-z0-9]+/g, '')
                    // replace all white space with single spaces
                    .replace(/\s\s+/g, ' ')
                    // replace all white spaces with a dash
                    .replace(/ /g, '-'),
                );
            if (!tags.length) {
                return;
            }
            this.tags = this.tags.concat(tags);
            this.newTag = '';
        }
    }

    removeTag(detail: { isComplete: boolean; chipIndex: number }) {
        if (detail.isComplete) {
            this.tags.splice(detail.chipIndex, 1);
        }
    }

}
